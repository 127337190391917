import { Scene } from "phaser";
import i18n from "@/plugins/i18n";
import store from "@/store";
import router from "@/router";
import _ from "lodash";

export default class LandingScene extends Scene {
  constructor() {
    super({ key: "LandingScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.questions = data.questions;
    this.friend = data.friend;
    this.bossRound = data.bossRound;
    this.env = data.env;
    this.noOfRounds = data.noOfRounds;
    this.difficulty = data.difficulty;
  }

  selectedOption = -1;

  create() {
    this._backgroundImage = this.add
      .image(0, 0, "backgroundImage")
      .setDisplaySize(480, 854)
      .setOrigin(0);
    this._gameTitle = this.add.image(240, 320, "gameTitleImage").setScale(0.8);

    if (
      store.state.settings.data.cache &&
      store.state.gameCache.data &&
      store.state.event.data.record.levelId == store.state.gameCache.data.level
    ) {
      this._continueBtn = this.add
        .image(240, 450, "optionButton")
        .setScale(0.35);
      this._continueTxt = this.add.text(
        240,
        450,
        i18n.t("miniGames.general.resume"),
        {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 20,
          color: "#000",
          align: "center",
        }
      );

      this._newBtn = this.add.image(240, 520, "optionButton").setScale(0.35);
      this._newTxt = this.add.text(
        240,
        520,
        i18n.t("miniGames.general.newGame"),
        {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 20,
          color: "#000",
          align: "center",
        }
      );

      this.tweens.add({
        targets: this._continueBtn,
        scaleX: 0.38,
        scaleY: 0.38,
        ease: "Linear",
        duration: 500,
        yoyo: true,
        repeat: -1,
      });

      this.tweens.add({
        targets: this._continueTxt,
        scaleX: 1.1,
        scaleY: 1.1,
        ease: "Linear",
        duration: 500,
        yoyo: true,
        repeat: -1,
      });

      this.bgSound = this.sound.add("bg");
      this.bgSound.setVolume(
        store.state.settings.data.audio.music *
          store.state.settings.data.audio.master
      );
      this.bgSound.loop = true;

      this._continueTxt.on(
        "pointerdown",
        () => {
          this._backgroundImage.visible = false;
          this._gameTitle.visible = false;
          this._continueBtn.visible = false;
          this._continueTxt.visible = false;
          this._newBtn.visible = false;
          this._newTxt.visible = false;
          this.bgSound.play();

          if (store.state.gameCache.data.isBossCollected) {
            let _event_data = _.cloneDeep(store.state.event.data);
            _event_data.boss.isCollected = true;
            store.commit("updateEvent", _event_data);
          }

          this.scene.launch("PlayScene", {
            avatar: this.avatar,
            round: store.state.gameCache.data.round,
            questions: this.questions,
            friend: this.friend,
            score: store.state.gameCache.data.score,
            bossRound: store.state.gameCache.data.isBossOver
              ? -1
              : this.selectedOption == 2
              ? 1
              : this.bossRound,
            forceFriend: this.selectedOption == 1 ? true : false,
            noOfRounds: this.noOfRounds,
            difficulty: this.difficulty,
            countCorrect: store.state.gameCache.data.countCorrect,
            avatarX: 220,
          });
        },
        this
      );
      this._continueTxt.setOrigin(0.5);
      this._continueTxt.setPadding(10);
      this._continueTxt.setInteractive({ useHandCursor: true });
      this._continueTxt.setFixedSize(200, 40);

      this._newTxt.on(
        "pointerdown",
        () => {
          this._backgroundImage.visible = false;
          this._gameTitle.visible = false;
          this._continueBtn.visible = false;
          this._continueTxt.visible = false;
          this._newBtn.visible = false;
          this._newTxt.visible = false;
          this.bgSound.play();

          this.scene.launch("PlayScene", {
            avatar: this.avatar,
            round: 1,
            questions: this.questions,
            friend: this.friend,
            score: 0,
            bossRound: this.selectedOption == 2 ? 1 : this.bossRound,
            forceFriend: this.selectedOption == 1 ? true : false,
            noOfRounds: this.noOfRounds,
            difficulty: this.difficulty,
            countCorrect: 0,
            avatarX: 220,
          });
        },
        this
      );
      this._newTxt.setOrigin(0.5);
      this._newTxt.setPadding(10);
      this._newTxt.setInteractive({ useHandCursor: true });
      this._newTxt.setFixedSize(200, 40);
    } else {
      this._continueBtn = this.add
        .image(240, 450, "optionButton")
        .setScale(0.35);
      this._continueTxt = this.add.text(
        240,
        450,
        i18n.t("miniGames.general.continue"),
        {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 20,
          color: "#000",
          align: "center",
        }
      );

      this.tweens.add({
        targets: this._continueBtn,
        scaleX: 0.38,
        scaleY: 0.38,
        ease: "Linear",
        duration: 500,
        yoyo: true,
        repeat: -1,
      });

      this.tweens.add({
        targets: this._continueTxt,
        scaleX: 1.1,
        scaleY: 1.1,
        ease: "Linear",
        duration: 500,
        yoyo: true,
        repeat: -1,
      });

      this.bgSound = this.sound.add("bg");
      this.bgSound.setVolume(
        store.state.settings.data.audio.music *
          store.state.settings.data.audio.master
      );
      this.bgSound.loop = true;

      if (store.state.settings.data.debug) {
        this.debug_bg = this.add
          .graphics()
          .fillStyle(0x000000, 1)
          .fillRect(130, 492, 220, 30);
        this.debug_dot = this.add.circle(160, 508, 5, 0xff0000);
        this.debug_txt = this.add.text(180, 500, "DEBUG OPTIONS", {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 15,
          color: "#fff",
        });
        this.radioGroup = this.add.group();
        this.selectedOption = 0;
        let options = [
          "Normal round",
          "Normal round with friend",
          "Boss round",
          "Auto Play",
        ];

        for (let i = 0; i < 4; i++) {
          let radioButton = this.add.circle(150, 550 + i * 40, 10, 0xffffff);
          if (i == this.selectedOption) {
            radioButton.setFillStyle(0xfce303);
          }
          radioButton.setInteractive();
          radioButton.on("pointerdown", () => {
            this.radioGroup.children.iterate(function (radio) {
              radio.setFillStyle(0xffffff);
            });
            radioButton.setFillStyle(0xfce303);
            this.selectedOption = i;
          });
          radioButton.lableTxt = this.add.text(170, 540 + i * 40, options[i], {
            fontFamily: "Sassoon, sans-serif",
            fontSize: 15,
            color: "#000",
          });
          this.radioGroup.add(radioButton);
        }
      }

      this._continueTxt.on(
        "pointerdown",
        () => {
          if (this.selectedOption == 3) {
            let score = Math.floor(Math.random() * (1000 - 900 + 1)) + 900;
            this.event_data = _.cloneDeep(store.state.event.data);
            this.event_data.submission.countCorrect =
              Math.floor(Math.random() * (10 - 7 + 1)) + 7;
            this.event_data.submission.accuracy = score / 10;
            this.event_data.submission.star = 3;
            this.event_data.submission.score = score;
            this.event_data.submission.submission += 1;
            this.event_data.boss.isCollected = true;
            this.event_data.record.attempt += 1;
            this.event_data.difficulty = this.difficulty;
            this.event_data.record.submission += 1;
            this.event_data.record.topScoreOld =
              this.event_data.record.topScore;
            this.event_data.record.topStarOld = this.event_data.record.topStar;
            if (score > this.event_data.record.topScore) {
              this.event_data.record.topScore = score;
              this.event_data.record.topStar = 3;
            }

            this.event_data.gameLoot = Math.floor(
              Math.random() * (5 - 3 + 1) + 3
            );
            store.commit("updateEvent", this.event_data);

            this.time.delayedCall(100, () => {
              router.push({
                name: "PageEventGameScore",
                params: {
                  id: store.state.event.data.record.levelId,
                },
              });
            });
          } else {
            this._backgroundImage.visible = false;
            this._gameTitle.visible = false;
            this._continueBtn.visible = false;
            this._continueTxt.visible = false;

            if (store.state.settings.data.debug) {
              this.radioGroup.children.entries.forEach((element) => {
                element.visible = false;
                element.lableTxt.visible = false;
              });
              this.debug_bg.visible = false;
              this.debug_txt.visible = false;
              this.debug_dot.visible = false;
            }
            this.bgSound.play();

            this.scene.launch("PlayScene", {
              avatar: this.avatar,
              round: 1,
              questions: this.questions,
              friend: this.friend,
              score: 0,
              bossRound: this.selectedOption == 2 ? 1 : this.bossRound,
              forceFriend: this.selectedOption == 1 ? true : false,
              noOfRounds: this.noOfRounds,
              difficulty: this.difficulty,
              countCorrect: 0,
              avatarX: 220,
            });
          }
        },
        this
      );
      this._continueTxt
        .setOrigin(0.5)
        .setPadding(10)
        .setInteractive({ useHandCursor: true })
        .setFixedSize(200, 40);
    }
  }

  update() {
    this.bgSound.setVolume(
      store.state.settings.data.audio.music *
        store.state.settings.data.audio.master
    );
  }
}
