var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"screen-wrap"},[_c('v-app',{staticClass:"hmc-app"},[_c('v-main',{staticClass:"main-body"},[_c('v-container',{staticClass:"app-body"},[(_vm.recordApi.isError)?_c('ApiErrorDialog',{attrs:{"api":_vm.recordApi}}):_vm._e(),(_vm.inventoryApi.isError)?_c('ApiErrorDialog',{attrs:{"api":_vm.inventoryApi}}):_vm._e(),(_vm.PlayerApi.isError)?_c('ApiErrorDialog',{attrs:{"api":_vm.PlayerApi}}):_vm._e(),(_vm.finished)?_c('FinishedBanner',{attrs:{"message":this.recordApi.isLoading
              ? 'saving record...'
              : this.inventoryApi.isLoading
              ? 'saving inventory...'
              : this.PlayerApi.isLoading
              ? 'saving player data...'
              : 'saved'}}):_vm._e(),(_vm.showReward)?_c('CompletionGift',{attrs:{"callback":_vm.showScoreRe,"callbackClose":_vm.callbackClose}}):_vm._e(),(_vm.showScore)?_c('Score',{attrs:{"difficulty":_vm.difficulty,"score":_vm.score,"star":_vm.event.submission.star,"bossIsCollected":_vm.event.boss.isCollected,"bossImg":_vm.event.boss.img,"bossName":_vm.event.boss.name,"playAgain":"PageMiningEnter"}}):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }